import { Toparea } from "./components/Toparea";

function App() {




  return (
    <div className="App">

      {/* <Loadscreen loading={loading} /> */}

   <Toparea />
   


    </div>
  );
}

export default App;
