import React from 'react'
import buybtn from '../assets/imgs/buybtn.png'
import one from '../assets/imgs/1.png'
import two from '../assets/imgs/2.png'
import three from '../assets/imgs/3.png'
import wheel from '../assets/imgs/4.png'
import t1 from '../assets/imgs/t1.png'
import t2 from '../assets/imgs/t2.png'
import t3 from '../assets/imgs/t3.png'
import buybtn2 from '../assets/imgs/buybtn2.png'

import { FaXTwitter, FaTelegram, } from "react-icons/fa6";
import dx from '../assets/imgs/dexscreener.svg'

import { motion } from 'framer-motion'

export const Toparea = () => {
    return (
        <>
            <div className='navbar'>
                <div className="icons">
                    <a href="https://twitter.com/mgpa_eth">
                        <FaXTwitter className="ico" />
                    </a>
                    <a href="https://t.me/mpgaeth">
                        <FaTelegram className="ico" /></a>                    <a href="https://dexscreener.com/ethereum/0x42be52ffd67a032da1c0308b641833d53521ccd4">
                        <img src={dx} alt="dx" className='dx' /></a>
                </div>
                <img src={buybtn} alt="" className='buybtn' />
            </div>

            <div className="body">
                <div className="top top1">
                    <motion.img whileHover={{ scale: 0.92 }} // Shrink effect
                        transition={{ type: "spring", stiffness: 500 }} // Spring-like effect
                        src={one} alt="" className='img img1' />
                    <img src={t1} alt="" className='text t1' />
                    <img src={buybtn2} alt="" className='buybtn2' />
                </div>

                <div className="top top2">
                    <img src={t2} alt="" className='text t2' />
                    <motion.img whileHover={{ scale: 0.92 }} // Shrink effect
                        transition={{ type: "spring", stiffness: 500 }} // Spring-like effect
                        src={three} alt="" className='img img2' />
                </div>

                <div className="tokenomics">
                    <img src={t3} alt="" className='t3' />
                    <div className="linearea">
                        <motion.div
                            className="line ad">What's the Token Called? : Make Pepe Great Again On ETH</motion.div>
                        <motion.div
                            className="line ad">What's the Ticker? : $MPGA</motion.div>
                        <motion.div
                            className="line ad">What's the Supply? : 69 milly</motion.div>
                        <motion.div
                            className="line ad">What are the Taxes? : 0 Taxes - we are libertarians</motion.div>
                        <motion.div
                            className="line ad">What's the LP Status? : Burnt my lord.</motion.div>
                    </div>

                    <div className="icons">
                        <a href="https://twitter.com/mgpa_eth">
                            <FaXTwitter className="ico" />
                        </a>
                        <a href="https://t.me/mpgaeth">
                            <FaTelegram className="ico" /></a>
                        <a href="https://dexscreener.com/ethereum/0x42be52ffd67a032da1c0308b641833d53521ccd4/">
                            <img src={dx} alt="dx" className='dx' /></a>
                    </div>
                    <motion.img
                        whileHover={{ left: "-1rem" }} // Shrink effect
                        transition={{ type: "spring", stiffness: 100 }} // Spring-like effect
                        src={two} alt="" className='two' />

                    <div src="" alt="" className="imgcnt">
                        <motion.img animate={{ rotate: 360 }} // Rotate 360 degrees

                            transition={{
                                duration: 4, // Duration of one complete spin in seconds
                                repeat: Infinity, // Repeat the animation indefinitely
                                ease: "linear" // Ensures a smooth, constant rotation
                            }}
                            src={wheel} alt="" className='wheel' />

                    </div>

                </div>

                <div className="footer"></div>

            </div>
        </>
    )
}
